import { FC } from 'react';
import classNames from 'classnames';

import styles from './SharedCarouselDot.scss';

type Props = {
    size?: 'small' | 'medium';
    dark?: boolean;
    isActive: boolean;
    onClick?: () => void;
};

export const SharedCarouselDot: FC<Props> = ({ isActive, onClick, size = 'medium', dark }) => {
    return (
        <span
            className={classNames(styles.dot, styles[size], {
                [styles.activeDot]: isActive,
                [styles.dark]: dark,
            })}
            role="button"
            tabIndex={0}
            onClick={onClick}
            onKeyPress={onClick}
        />
    );
};
