import PropTypes from 'prop-types';
import { createFragmentContainer, graphql } from 'react-relay/legacy';
import { relatedPagesTexts } from '../../shared/sharedMessages';
import { MIN_RELATED_PAGES, MAX_RELATED_PAGES } from '../../constants/sbConstants';

const SharedRelatedPagesComponent = ({ viewer, itemSearch, LinksBlockComponent, borderTop }) => {
    const { appliedFilters, relatedPages, filters } = itemSearch;
    if (appliedFilters && relatedPages?.length >= MIN_RELATED_PAGES) {
        const { anchorTextPrefix, linksBlockTitle } =
            relatedPagesTexts(appliedFilters, filters) || {};

        if (!linksBlockTitle) {
            return null;
        }

        return (
            <LinksBlockComponent
                viewer={viewer}
                itemSearch={itemSearch}
                title={linksBlockTitle}
                anchorTextPrefix={anchorTextPrefix}
                links={relatedPages.slice(0, MAX_RELATED_PAGES)}
                borderTop={borderTop}
                item={null}
            />
        );
    }

    return null;
};

SharedRelatedPagesComponent.propTypes = {
    viewer: PropTypes.object.isRequired,
    itemSearch: PropTypes.object,
    LinksBlockComponent: PropTypes.oneOfType([PropTypes.func, PropTypes.object]).isRequired,
    borderTop: PropTypes.bool,
};

export const SharedRelatedPages = createFragmentContainer(SharedRelatedPagesComponent, {
    viewer: graphql`
        fragment SharedRelatedPages_viewer on Viewer {
            ...SharedRespLinksBlock_viewer
            ...SharedMobileLinksBlock_viewer
        }
    `,
    itemSearch: graphql`
        fragment SharedRelatedPages_itemSearch on ItemSearchQueryConnection {
            appliedFilters {
                name
                values {
                    displayName
                }
            }
            filters {
                name
                values {
                    displayName
                }
            }
            ...SharedRespLinksBlock_itemSearch
            ...SharedMobileLinksBlock_itemSearch
            relatedPages {
                ...SharedRespLinksBlock_links
                ...SharedMobileLinksBlock_links
            }
        }
    `,
});
