const PARAM_CROP_SMART = 'smart';
const PARAM_CROP_SAFE = 'safe';

export type fastlyOptionsType = {
    [key: string]: string | number | null;
};

/**
 * Returns a single url with passed fastly options as query params.
 * If parameter value is null, it will be delete from query params.
 *
 * @export
 * @param {string} url - url of the image
 * @param {object} options - desired image options
 * @returns {string}
 */
export const getImageSrc = (url: string | null, options: fastlyOptionsType): string | null => {
    if (!url) {
        return null;
    }
    const optionsCombined: fastlyOptionsType = {
        auto: 'webp',
        quality: 60,
        ...options,
    };
    const [path, searchString] = url.split('?');
    const queryParams = new URLSearchParams(searchString);

    Object.entries(optionsCombined).forEach(([key, val]) => {
        if (val === null) {
            queryParams.delete(key);
        } else {
            queryParams.set(key, String(val));
        }
    });

    const cropParams = queryParams.get('crop');
    if (typeof cropParams === 'string' && (options.width || options.height)) {
        const cropParamSegments = cropParams.split(',');
        if (
            !cropParamSegments.includes(PARAM_CROP_SMART) &&
            !cropParamSegments.includes(PARAM_CROP_SAFE)
        ) {
            // When specifying smart cropping, content-aware algorithms attempt to crop an image
            // to a fixed aspect ratio while intelligently focusing on the most important visual
            // content, including the detection of faces.
            cropParamSegments.push(PARAM_CROP_SMART);
            queryParams.set('crop', cropParamSegments.join(','));
        }
    }

    return `${path}?${queryParams.toString()}`;
};
