import { Fragment } from 'react';
import PropTypes from 'prop-types';
import { createFragmentContainer, graphql } from 'react-relay/legacy';
import { connect } from 'react-redux';
import get from 'lodash.get';
import { showTradeOnApprovalBanner } from '../SbRespTradeOnApprovalPromo/SbRespTradeOnApprovalPromoBanner/sbRespTradeOnApprovalPromoHelpers';
import { pageTypeConstants } from '../../constants/pageTypeConstants';
import { getCategories, getLowestCategory, CATEGORY_LEVEL_1 } from '../../utils/categoryHelpers';

const SbSharedTopTileControllerComponent = props => {
    const { itemSearch, viewer, isUserTypeTrade, tradeOnApprovalBanner, priceTiles } = props;

    const showCategoryTiles = !get(itemSearch, 'browseContentModule');
    const shouldShowPriceTiles = isUserTypeTrade && get(itemSearch, 'priceTiles.length') > 2;

    const appliedFilters = get(itemSearch, 'appliedFilters') || [];
    const usRegions = get(viewer, 'regions') || [];
    const shouldShowTradeOnApprovalBanner =
        isUserTypeTrade &&
        showTradeOnApprovalBanner({ appliedFilters, usRegions }) &&
        !!tradeOnApprovalBanner;

    const lowestCategory = getLowestCategory(getCategories(appliedFilters));
    const shouldShowDesignTiles =
        viewer.showCreatorDesignTiles &&
        itemSearch.pageType === pageTypeConstants.CREATOR &&
        (!lowestCategory || get(lowestCategory, 'name') === CATEGORY_LEVEL_1);

    let content = null;

    // price tiles are currently only on desktop
    if (priceTiles && shouldShowPriceTiles) {
        content = props.priceTiles;
    } else if (shouldShowTradeOnApprovalBanner) {
        content = props.tradeOnApprovalBanner;
    } else if (shouldShowDesignTiles) {
        content = props.designTiles;
    } else if (showCategoryTiles) {
        content = props.categoryTiles;
    }

    return content && <Fragment>{content}</Fragment>;
};

SbSharedTopTileControllerComponent.propTypes = {
    itemSearch: PropTypes.object.isRequired,
    viewer: PropTypes.object.isRequired,
    categoryTiles: PropTypes.node,
    designTiles: PropTypes.node,
    tradeOnApprovalBanner: PropTypes.node,
    priceTiles: PropTypes.node,
    isUserTypeTrade: PropTypes.bool,
};

const mapStateToProps = state => {
    const { header } = state;
    return {
        isUserTypeTrade: header.isUserTypeTrade,
    };
};

export const SbSharedTopTileController = createFragmentContainer(
    connect(mapStateToProps)(SbSharedTopTileControllerComponent),
    {
        itemSearch: graphql`
            fragment SbSharedTopTileController_itemSearch on ItemSearchQueryConnection {
                pageType
                appliedFilters {
                    name
                    values {
                        code
                        urlLabel
                    }
                }
                priceTiles(isTrade: $isTrade) {
                    displayName
                }
                browseContentModule(contentModuleId: $contentModuleId) {
                    moduleType
                }
            }
        `,
        viewer: graphql`
            fragment SbSharedTopTileController_viewer on Viewer {
                showCreatorDesignTiles: featureFlag(feature: "sbCreatorDesignTiles")
                regions(
                    regionType: ITEM_LOCATION
                    regionBoundaryTypes: [METROPOLITAN, DISTRICT, DOMESTIC]
                    address: { country: "us" }
                ) @include(if: $isTrade) {
                    regionName
                    urlLabel
                }
            }
        `,
    }
);
