/**
 * @generated SignedSource<<07b0695fde2ae978c76a86bbf7f307d4>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SbSharedTopTileController_viewer$data = {
  readonly regions?: ReadonlyArray<{
    readonly regionName: string | null;
    readonly urlLabel: string | null;
  } | null> | null;
  readonly showCreatorDesignTiles: boolean | null;
  readonly " $fragmentType": "SbSharedTopTileController_viewer";
};
export type SbSharedTopTileController_viewer$key = {
  readonly " $data"?: SbSharedTopTileController_viewer$data;
  readonly " $fragmentSpreads": FragmentRefs<"SbSharedTopTileController_viewer">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "isTrade"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "SbSharedTopTileController_viewer",
  "selections": [
    {
      "alias": "showCreatorDesignTiles",
      "args": [
        {
          "kind": "Literal",
          "name": "feature",
          "value": "sbCreatorDesignTiles"
        }
      ],
      "kind": "ScalarField",
      "name": "featureFlag",
      "storageKey": "featureFlag(feature:\"sbCreatorDesignTiles\")"
    },
    {
      "condition": "isTrade",
      "kind": "Condition",
      "passingValue": true,
      "selections": [
        {
          "alias": null,
          "args": [
            {
              "kind": "Literal",
              "name": "address",
              "value": {
                "country": "us"
              }
            },
            {
              "kind": "Literal",
              "name": "regionBoundaryTypes",
              "value": [
                "METROPOLITAN",
                "DISTRICT",
                "DOMESTIC"
              ]
            },
            {
              "kind": "Literal",
              "name": "regionType",
              "value": "ITEM_LOCATION"
            }
          ],
          "concreteType": "Region",
          "kind": "LinkedField",
          "name": "regions",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "regionName",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "urlLabel",
              "storageKey": null
            }
          ],
          "storageKey": "regions(address:{\"country\":\"us\"},regionBoundaryTypes:[\"METROPOLITAN\",\"DISTRICT\",\"DOMESTIC\"],regionType:\"ITEM_LOCATION\")"
        }
      ]
    }
  ],
  "type": "Viewer",
  "abstractKey": null
};

(node as any).hash = "589311a307c98a1c54c43bdff0711f83";

export default node;
