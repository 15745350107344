import PropTypes from 'prop-types';

import { Swiper } from 'dibs-pinch-zoom-swipe';

import { SWIPER_TABLET_TILES_COUNT } from '../../constants/sbConstants';

// styles
import styles from './sbRespTilesSwiper.scss';

const SbRespTilesSwiper = ({ isTablet, children, displayUriRef }) => {
    if (!isTablet) {
        return children;
    }

    return (
        <Swiper
            classNames={{ item: styles.item }}
            itemsPerPage={SWIPER_TABLET_TILES_COUNT}
            /**
             * Whe need to re-initialize the swiper model when the children change. We know that
             * can happen when the `displayUriRef` changes, so we just remount the components
             * with the key hack.
             */
            key={displayUriRef}
        >
            {children}
        </Swiper>
    );
};

SbRespTilesSwiper.propTypes = {
    isTablet: PropTypes.bool,
    children: PropTypes.node.isRequired,
    displayUriRef: PropTypes.string.isRequired,
};

export { SbRespTilesSwiper };
