import { FC, ReactNode, memo, useEffect } from 'react';
import { useFragment, graphql } from 'react-relay';
import classnames from 'classnames';
import { Link } from 'dibs-elements/exports/Link';
import { FormattedMessage } from 'dibs-react-intl';

import { SbSharedContentModuleBannerText } from './SbSharedContentModuleBannerText';
import { SbSharedContentModuleBannerImage } from './SbSharedContentModuleBannerImage';
import { trackInteraction } from './SbSharedContentModuleBannerTiles';

import { useServerVarsContext } from '../../global/ServerVarsContext/ServerVarsContext';

import { SbSharedContentModuleBannerItem_banner$key } from './__generated__/SbSharedContentModuleBannerItem_banner.graphql';

import styles from './SbSharedContentModuleBannerItem.scss';

type Props = {
    isSalePage: boolean;
    termsLink: string;
    banner: SbSharedContentModuleBannerItem_banner$key | null | undefined;
    renderImage: ({
        src,
        alt,
        className,
        srcSet,
        sizes,
    }: {
        src: string;
        alt: string;
        className: string;
        srcSet: string;
        sizes?: string;
    }) => ReactNode;
};

export const SbSharedContentModuleBannerItem: FC<Props> = memo(
    ({ banner: bannerRef, renderImage, termsLink, isSalePage }) => {
        const { isMobile } = useServerVarsContext();
        const banner = useFragment(
            graphql`
                fragment SbSharedContentModuleBannerItem_banner on BrowseContentModuleBannerType {
                    ...SbSharedContentModuleBannerText_banner
                    ...SbSharedContentModuleBannerImage_banner
                    format
                    ctaUrl
                    textPosition
                    backgroundColor
                    bannerImageWidth
                }
            `,
            bannerRef
        );
        const { format, textPosition, backgroundColor, bannerImageWidth, ctaUrl } = banner || {};

        const isStaticBanner = format === 'BURN_IN' && !isMobile;
        const isHalfWidth = bannerImageWidth === 'HALF' || !isStaticBanner;
        const showBackgroungColor = isHalfWidth && !!backgroundColor;

        const wrapperClasses = classnames(styles.wrapper, {
            [styles.reverse]: textPosition === 'right',
            [styles.border]: !backgroundColor && !isHalfWidth,
        });

        useEffect(() => {
            if (isSalePage) {
                trackInteraction('sale banner carousel');
            }
        }, [isSalePage]);

        if (isStaticBanner) {
            return (
                <div className={styles.staticContainer}>
                    <div className={wrapperClasses}>
                        <div className={styles.staticText}>
                            <SbSharedContentModuleBannerText
                                banner={banner || null}
                                isStaticBanner={isStaticBanner}
                            />
                        </div>
                        <SbSharedContentModuleBannerImage
                            renderImage={renderImage}
                            banner={banner || null}
                            isStaticBanner={isStaticBanner}
                            isHalfWidth={isHalfWidth}
                        />
                    </div>
                </div>
            );
        }
        return (
            <div
                className={classnames(styles.htmlContainer, {
                    [styles.border]: !backgroundColor && (isHalfWidth || isMobile),
                })}
                style={{
                    backgroundColor: showBackgroungColor ? backgroundColor : undefined,
                }}
            >
                <div className={wrapperClasses}>
                    <>
                        <SbSharedContentModuleBannerText
                            banner={banner || null}
                            isStaticBanner={isStaticBanner}
                        />
                        {termsLink && !ctaUrl && (
                            <Link href={termsLink} className={styles.termsLink}>
                                <FormattedMessage
                                    id="abf.SbSharedContentModuleBannerTiles.termsLink"
                                    defaultMessage="Terms & Conditions"
                                />
                            </Link>
                        )}
                    </>

                    <SbSharedContentModuleBannerImage
                        renderImage={renderImage}
                        banner={banner || null}
                        isStaticBanner={isStaticBanner}
                        isHalfWidth={isHalfWidth}
                    />
                </div>
            </div>
        );
    }
);
