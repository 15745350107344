import { FC } from 'react';
import { createFragmentContainer, graphql } from 'react-relay/legacy';
import { trackEvent } from 'dibs-tracking';
import { Link } from 'dibs-elements/exports/Link';
import { SeoLink } from 'dibs-seo/exports/SeoLink';
import LazyImage from 'dibs-react-lazy-image';
import { getSrcsetString } from 'dibs-image-utils/exports/srcSet';
import { shopAll } from '../sbMessages';
import classNames from 'classnames';
import styles from './SbSharedRelatedCreator.scss';

import type { SbSharedRelatedCreator_relatedCreators$data } from './__generated__/SbSharedRelatedCreator_relatedCreators.graphql';

export type Props = {
    relatedCreators: SbSharedRelatedCreator_relatedCreators$data;
    isHidden?: boolean;

    /* EXPERIMENTAL PROPERTY -- DO NOT USE! */
    imageLoading?: 'lazy' | 'eager';
};
type RelatedCreator = {
    shopAllTitle: NonNullable<SbSharedRelatedCreator_relatedCreators$data['shopAllTitle']>;
    linkData: NonNullable<SbSharedRelatedCreator_relatedCreators$data['linkData']>;
    creatorName: NonNullable<SbSharedRelatedCreator_relatedCreators$data['creatorName']>;
    items: NonNullable<SbSharedRelatedCreator_relatedCreators$data['items']>;
};
type Item = NonNullable<RelatedCreator['items'][0]>;
type ImagePath = NonNullable<NonNullable<NonNullable<Item['photos']>[0]>['masterOrZoomPath']>;
type ItemType = {
    localizedPdpUrl: NonNullable<Item['localizedPdpUrl']>;
    title: NonNullable<Item['title']>;
    photos: NonNullable<NonNullable<{ masterOrZoomPath: ImagePath }>[]>;
};

const LAZY_IMAGE_VERTICAL_OFFSET: number = 200;
const CREATOR_ITEM_IMAGE_SIZES: string = '(max-width: 568px) 33vw, 15vw';

export const SbSharedRelatedCreatorComponent: FC<Props> = ({
    relatedCreators,
    isHidden,
    imageLoading,
}) => {
    const { shopAllTitle, linkData, creatorName, items } = relatedCreators as RelatedCreator;

    return (
        <div
            key={creatorName}
            className={classNames(styles.relatedCreatorTile, {
                [styles.hideTile]: isHidden,
            })}
        >
            <div className={styles.relatedCreatorName}>{creatorName}</div>
            <div className={styles.relatedCreatorItems}>
                {items.map(item => {
                    const { localizedPdpUrl, photos, title } = item as ItemType;
                    const imageSrc = photos?.[0]?.masterOrZoomPath;

                    const imageClassNames = `${imageLoading === 'eager' ? '' : 'lazyload '}${
                        styles.relatedCreatorItemImage
                    }`;
                    const imageProps =
                        imageLoading === 'eager'
                            ? { src: imageSrc }
                            : {
                                  'data-src': imageSrc,
                                  // "smallest possible transparent GIF image"
                                  // @see https://stackoverflow.com/a/9967193
                                  src: 'data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==',
                              };

                    return (
                        <Link
                            className={styles.relatedCreatorItem}
                            href={localizedPdpUrl}
                            key={imageSrc}
                            onClick={() =>
                                trackEvent({
                                    category: 'promo interaction',
                                    action: 'related creators module pdp click',
                                    label: 'none',
                                })
                            }
                        >
                            <>
                                {imageLoading && (
                                    <img
                                        loading={imageLoading}
                                        className={imageClassNames}
                                        srcSet={getSrcsetString(imageSrc)}
                                        sizes={CREATOR_ITEM_IMAGE_SIZES}
                                        alt={title}
                                        {...imageProps}
                                    />
                                )}
                                {!imageLoading && (
                                    <LazyImage
                                        placeholderClass={styles.placeholderClass}
                                        offsetVertical={LAZY_IMAGE_VERTICAL_OFFSET}
                                    >
                                        <img
                                            className={styles.relatedCreatorItemImage}
                                            src={imageSrc}
                                            srcSet={getSrcsetString(imageSrc)}
                                            sizes={CREATOR_ITEM_IMAGE_SIZES}
                                            alt={title}
                                        />
                                    </LazyImage>
                                )}
                            </>
                        </Link>
                    );
                })}
            </div>
            <div className={styles.shopAllTitle}>
                <SeoLink
                    linkData={linkData}
                    onClick={() =>
                        trackEvent({
                            category: 'promo interaction',
                            action: 'related creators module creator click',
                            label: 'none',
                        })
                    }
                    dataTn="shop-all-creator-items"
                >
                    {shopAll(shopAllTitle)}
                </SeoLink>
            </div>
        </div>
    );
};

export const SbSharedRelatedCreator = createFragmentContainer(SbSharedRelatedCreatorComponent, {
    relatedCreators: graphql`
        fragment SbSharedRelatedCreator_relatedCreators on RelatedCreatorType {
            shopAllTitle: title
            creatorName: pageObjectName
            linkData {
                path
                ...SeoLink_linkData
            }
            items {
                ... on Item {
                    title
                    localizedPdpUrl
                    photos(limit: 1) {
                        masterOrZoomPath
                    }
                }
            }
        }
    `,
});
