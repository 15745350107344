import { FC, ReactNode } from 'react';
import { createFragmentContainer, graphql } from 'react-relay/legacy';

import ArrowIcon from 'dibs-icons/exports/legacy/ArrowRight';
import { Link } from 'dibs-elements/exports/Link';

import { SbSharedContentModuleBanner_browseContentModule$data } from './__generated__/SbSharedContentModuleBanner_browseContentModule.graphql';

import styles from './SbSharedContentModuleBanner.scss';

const DEFAULT_MAX_IMG_WIDTH = 1126;

type BrowseContentModule = SbSharedContentModuleBanner_browseContentModule$data;

type SbSharedContentModuleBannerPhoto = {
    photo: BrowseContentModule['photo'];
    src: string;
    alt: BrowseContentModule['title'] | BrowseContentModule['subtitle'];
    imgClassName: string;
};

type Props = {
    browseContentModule: BrowseContentModule;
    renderImage: ({ photo, src, alt, imgClassName }: SbSharedContentModuleBannerPhoto) => ReactNode;
};

const SbSharedContentModuleBannerComponent: FC<Props> = ({ browseContentModule, renderImage }) => {
    const { photo, title, subtitle, description, link, linkText } = browseContentModule;

    const renderImageProps = {
        photo,
        src: `${photo}?width=${DEFAULT_MAX_IMG_WIDTH}`,
        imgClassName: styles.image,
        alt: title || subtitle || '',
    };

    return (
        <div className={styles.wrapper} data-tn="sb-cms-wrapper">
            {photo && (
                <div className={styles.imgWrapper} data-tn="sb-cms-image">
                    {renderImage(renderImageProps)}
                </div>
            )}
            <div className={styles.moduleWrapper} data-tn="sb-cms-content-wrapper">
                <div className={styles.module}>
                    {title && (
                        <div className={styles.title} data-tn="sb-cms-title">
                            {title}
                        </div>
                    )}
                    {subtitle && (
                        <div className={styles.subtitle} data-tn="sb-cms-subtitle">
                            {subtitle}
                        </div>
                    )}

                    {description && (
                        <div
                            className={styles.description}
                            dangerouslySetInnerHTML={{ __html: description }}
                            data-tn="sb-cms-description"
                        />
                    )}

                    {link && linkText && (
                        <Link href={link} className={styles.link} dataTn="sb-cms-link">
                            {linkText}
                            <ArrowIcon className={styles.arrow} />
                        </Link>
                    )}
                </div>
            </div>
        </div>
    );
};

export const SbSharedContentModuleBanner = createFragmentContainer(
    SbSharedContentModuleBannerComponent,
    {
        browseContentModule: graphql`
            fragment SbSharedContentModuleBanner_browseContentModule on ContentModuleBanner {
                photo
                title
                subtitle
                description
                link
                linkText
            }
        `,
    }
);
