import { FC, MutableRefObject, useReducer, useEffect, useRef, Reducer } from 'react';
import { createFragmentContainer, graphql } from 'react-relay/legacy';
import { Header } from 'dibs-elements/exports/Header';
import { Button } from 'dibs-elements/exports/Button';
import HeadingLevel from 'dibs-controlled-heading/exports/HeadingLevel';
import { trackEvent } from 'dibs-tracking';
import { SbSharedRelatedCreator } from './SbSharedRelatedCreator';
import { relatedCreatorsTitle, viewMore, viewLess } from '../sbMessages';
import { INITIAL_VISIBLE_RELATED_CREATORS } from '../../constants/sbConstants';
import { Visibility } from '../../components/global/Visibility';
import styles from './SbSharedRelatedCreators.scss';

import type { SbSharedRelatedCreators_itemSearch$data } from './__generated__/SbSharedRelatedCreators_itemSearch.graphql';

const TOGGLE_SHOW = 'toggleShow';

type Props = {
    itemSearch: SbSharedRelatedCreators_itemSearch$data;
    revealCreatorCount: number;
    classname?: string;
    headerClassname?: string;
};
type State = {
    wasExpanded: boolean | null;
    visibleCount: number;
};
type Action = { type: typeof TOGGLE_SHOW };

const SbSharedRelatedCreatorsComponent: FC<Props> = ({
    itemSearch,
    revealCreatorCount,
    classname,
    headerClassname,
}) => {
    const { relatedCreators, topCategoryL1, meta } = itemSearch;
    const creators = relatedCreators?.edges ?? [];
    const relatedCreatorsCount = creators.length;
    const creatorName = meta?.attributeDisplayName;

    const reducer: Reducer<State, Action> = (state, action) => {
        const wasExpanded = true;
        switch (action.type) {
            case TOGGLE_SHOW:
                if (state.visibleCount >= relatedCreatorsCount) {
                    return {
                        wasExpanded,
                        visibleCount: INITIAL_VISIBLE_RELATED_CREATORS,
                    };
                } else {
                    return {
                        wasExpanded,
                        visibleCount: state.visibleCount + revealCreatorCount,
                    };
                }
            default:
                return state;
        }
    };

    const [state, dispatch] = useReducer(reducer, {
        wasExpanded: null,
        visibleCount: INITIAL_VISIBLE_RELATED_CREATORS,
    });

    const viewMoreEl: MutableRefObject<HTMLButtonElement | null> = useRef(null);

    useEffect(() => {
        if (state.wasExpanded && viewMoreEl.current) {
            // There is incosistent behaviour among browsers about if element is focused or not after being clicked.
            // For accessibility reasons we need to focus on button after it is being clicked by mouse as well as by keypress.
            // Safari does not support focus() with preventScroll option, so it scrolls together with View More button.
            // Need workaround to keep scroll position fixed before and after click.
            const currentPosition = window.scrollY;
            viewMoreEl.current.focus();
            window.scrollTo(0, currentPosition);
        }
    }, [state.visibleCount, state.wasExpanded]);

    if (topCategoryL1 && creatorName && relatedCreatorsCount >= INITIAL_VISIBLE_RELATED_CREATORS) {
        return (
            <HeadingLevel>
                {Heading => (
                    <div className={`${styles.relatedCreatorsWrapper} ${classname}`}>
                        <div className={`${styles.relatedCreatorsHeader} ${headerClassname}`}>
                            <Header
                                title={relatedCreatorsTitle(topCategoryL1, creatorName)}
                                htmlElementType={Heading}
                            />
                        </div>
                        <Visibility
                            onContentVisible={() =>
                                trackEvent({
                                    category: 'promo interaction',
                                    action: 'related creators module displayed',
                                    label: 'none',
                                })
                            }
                        />
                        <div className={styles.relatedCreators}>
                            {creators.map((relatedCreator, index) => {
                                if (!relatedCreator?.node) {
                                    return null;
                                }
                                const creator = relatedCreator.node;
                                const shouldHideRelatedCreator = index + 1 > state.visibleCount;

                                return (
                                    <SbSharedRelatedCreator
                                        key={`related-creator-${index}`}
                                        relatedCreators={creator}
                                        isHidden={shouldHideRelatedCreator}
                                    />
                                );
                            })}
                        </div>
                        {relatedCreatorsCount > INITIAL_VISIBLE_RELATED_CREATORS && (
                            <Button
                                buttonRef={viewMoreEl}
                                type="secondary"
                                size="medium"
                                onClick={e => {
                                    e.currentTarget.blur();
                                    dispatch({ type: TOGGLE_SHOW });
                                }}
                                className={styles.viewMoreButton}
                                dataTn="related-creators-view-more"
                            >
                                {state.visibleCount >= relatedCreatorsCount ? viewLess : viewMore}
                            </Button>
                        )}
                    </div>
                )}
            </HeadingLevel>
        );
    } else {
        return null;
    }
};

export const SbSharedRelatedCreators = createFragmentContainer(SbSharedRelatedCreatorsComponent, {
    itemSearch: graphql`
        fragment SbSharedRelatedCreators_itemSearch on ItemSearchQueryConnection {
            meta {
                attributeDisplayName
            }
            topCategoryL1
            relatedCreators {
                edges {
                    node {
                        linkData {
                            path
                        }
                        ...SbSharedRelatedCreator_relatedCreators
                    }
                }
            }
        }
    `,
});
