import { FC } from 'react';
import { FormattedMessage } from 'dibs-react-intl';
import { SeoLink } from 'dibs-elements/exports/SeoLink';
import { SEGMENTS } from 'dibs-constants/exports/uriConstants';

import { SbSharedStorefrontLink_browseContentModule$data } from './__generated__/SbSharedStorefrontLink_browseContentModule.graphql';

import styles from './SbSharedStorefrontLink.scss';

type Props = {
    browseContentModule: SbSharedStorefrontLink_browseContentModule$data;
};

export const SbSharedStorefrontLinkBase: FC<Props> = ({ browseContentModule }) => {
    const seller = browseContentModule.seller;
    const company = seller?.sellerProfile?.company;

    if (!seller || !company) {
        return null;
    }

    const urlLabel = seller.sellerPreferences?.urlLabel;
    const isLinkable = !!urlLabel && seller.liveStorefrontProfile?.status === 'LIVE';
    const path = isLinkable ? `/${SEGMENTS.SELLER}/${urlLabel}/` : null;

    return (
        <div className={styles.wrapper}>
            (
            <FormattedMessage
                id="SbSharedStorefrontLink.bioProvidedBy"
                defaultMessage="Biography provided by"
            />{' '}
            <SeoLink
                linkData={{
                    path,
                    isLinkable,
                }}
                dataTn="bio-seller-link"
            >
                {company}
            </SeoLink>
            )
        </div>
    );
};
