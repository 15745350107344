import { FC } from 'react';
import { createFragmentContainer, graphql } from 'react-relay/legacy';
import { FormattedMessage } from 'dibs-react-intl';
import { pageTypeConstants } from '../../constants/pageTypeConstants';
import { Button } from 'dibs-elements/exports/Button';
import { viewMore } from '../sbMessages';

import { SbRespDesignsDescriptionTile_itemSearch$data } from './__generated__/SbRespDesignsDescriptionTile_itemSearch.graphql';

import styles from './SbRespDesignsDescriptionTile.scss';

type Props = {
    itemSearch: SbRespDesignsDescriptionTile_itemSearch$data;
    onViewMoreClick?: () => void;
};

const SbRespDesignsDescriptionTileComponent: FC<Props> = ({ itemSearch, onViewMoreClick }) => {
    const { appliedFilters } = itemSearch;
    const creatorFilter = (appliedFilters || []).find(f => f?.name === pageTypeConstants.CREATOR);
    const creatorName = creatorFilter?.values?.[0]?.displayName;
    if (!creatorName) {
        return null;
    }
    return (
        <div className={styles.container}>
            <div className={styles.title}>
                <FormattedMessage
                    id="sb.contentModule.SbRespDesignsDescriptionTile.title"
                    defaultMessage="Iconic Designs from {creatorName}"
                    values={{ creatorName }}
                />
            </div>
            {onViewMoreClick ? (
                <Button
                    type="transparent"
                    size="small"
                    dataTn="iconic-designs-view-more"
                    onClick={onViewMoreClick}
                    className={styles.viewMoreButton}
                >
                    {viewMore}
                </Button>
            ) : (
                <div className={styles.divider} />
            )}
            <div className={onViewMoreClick ? styles.smallFontBody : styles.regularFontBody}>
                <FormattedMessage
                    id="sb.contentModule.SbRespDesignsDescriptionTile.body"
                    defaultMessage="Explore timeless pieces that have earned their place in history."
                />
            </div>
        </div>
    );
};

export const SbRespDesignsDescriptionTile = createFragmentContainer(
    SbRespDesignsDescriptionTileComponent,
    {
        itemSearch: graphql`
            fragment SbRespDesignsDescriptionTile_itemSearch on ItemSearchQueryConnection {
                appliedFilters {
                    name
                    values {
                        displayName
                    }
                }
            }
        `,
    }
);
