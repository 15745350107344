import { FC, ReactNode } from 'react';
import { useFragment, graphql } from 'react-relay';
import classnames from 'classnames';

import { getSrcsetString } from 'dibs-image-utils/exports/srcSet';
import { useServerVarsContext } from '../../global/ServerVarsContext/ServerVarsContext';

import { SbSharedContentModuleBannerImage_banner$key } from './__generated__/SbSharedContentModuleBannerImage_banner.graphql';

import styles from './SbSharedContentModuleBannerImage.scss';

const DEFAULT_MAX_IMG_WIDTH = 1018;

type Props = {
    banner: SbSharedContentModuleBannerImage_banner$key | null | undefined;
    isStaticBanner: boolean;
    isHalfWidth: boolean;
    renderImage: ({
        src,
        alt,
        className,
        srcSet,
        sizes,
    }: {
        src: string;
        alt: string;
        className: string;
        srcSet: string;
        sizes?: string;
    }) => ReactNode;
};

export const SbSharedContentModuleBannerImage: FC<Props> = ({
    renderImage,
    banner: bannerRef,
    isStaticBanner,
    isHalfWidth,
}) => {
    const { isMobile } = useServerVarsContext();
    const banner = useFragment(
        graphql`
            fragment SbSharedContentModuleBannerImage_banner on BrowseContentModuleBannerType {
                ctaCopy
                mainImage
            }
        `,
        bannerRef
    );
    const { mainImage, ctaCopy } = banner || {};
    const showHalfWidth = isHalfWidth && !isMobile;

    const image = renderImage({
        srcSet: getSrcsetString(mainImage || '') || '',
        sizes: `
            (max-width: 569px) 100vw,
            (max-width: 768px) 50vw,
            60vw
        `,
        className: styles.image,
        alt: (isStaticBanner && ctaCopy) || '',
        src: `${mainImage}?width=${DEFAULT_MAX_IMG_WIDTH}`,
    });

    return (
        <div
            className={classnames(styles.imageContainer, {
                [styles.halfWidth]: showHalfWidth,
            })}
        >
            {image}
        </div>
    );
};
