/**
 * @generated SignedSource<<df39258967bd6e22feb296c11fddf246>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type BrowseContentModuleBannerFormat = "BURN_IN" | "CAPTION" | "%future added value";
export type BrowseContentModuleBannerImageWidth = "FULL" | "HALF" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type SbSharedContentModuleBannerItem_banner$data = {
  readonly backgroundColor: string | null;
  readonly bannerImageWidth: BrowseContentModuleBannerImageWidth | null;
  readonly ctaUrl: string | null;
  readonly format: BrowseContentModuleBannerFormat | null;
  readonly textPosition: string | null;
  readonly " $fragmentSpreads": FragmentRefs<"SbSharedContentModuleBannerImage_banner" | "SbSharedContentModuleBannerText_banner">;
  readonly " $fragmentType": "SbSharedContentModuleBannerItem_banner";
};
export type SbSharedContentModuleBannerItem_banner$key = {
  readonly " $data"?: SbSharedContentModuleBannerItem_banner$data;
  readonly " $fragmentSpreads": FragmentRefs<"SbSharedContentModuleBannerItem_banner">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SbSharedContentModuleBannerItem_banner",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SbSharedContentModuleBannerText_banner"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SbSharedContentModuleBannerImage_banner"
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "format",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "ctaUrl",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "textPosition",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "backgroundColor",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "bannerImageWidth",
      "storageKey": null
    }
  ],
  "type": "BrowseContentModuleBannerType",
  "abstractKey": null
};

(node as any).hash = "5cbc094502b12d80b8bbd3bc82c9c914";

export default node;
