/**
 * @generated SignedSource<<9cd16f8d12c8218b3d88de8a372022e2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SharedRelatedPages_viewer$data = {
  readonly " $fragmentSpreads": FragmentRefs<"SharedMobileLinksBlock_viewer" | "SharedRespLinksBlock_viewer">;
  readonly " $fragmentType": "SharedRelatedPages_viewer";
};
export type SharedRelatedPages_viewer$key = {
  readonly " $data"?: SharedRelatedPages_viewer$data;
  readonly " $fragmentSpreads": FragmentRefs<"SharedRelatedPages_viewer">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SharedRelatedPages_viewer",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SharedRespLinksBlock_viewer"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SharedMobileLinksBlock_viewer"
    }
  ],
  "type": "Viewer",
  "abstractKey": null
};

(node as any).hash = "0dd3e6e3e8113a306e8872b9b9b832b6";

export default node;
